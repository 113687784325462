import React from "react";
import "../container/Scholarships.css";
import ScholarshipGrad from '../img/scholars-program/studentProfile.webp';


import ProgramPlan from '../img/scholars-program/scholar-pragram-plan.png';
import { useMediaQuery, useTheme } from "@material-ui/core";
import program1 from '../img/scholars-program/program_1.png';
import program2 from '../img/scholars-program/program_2.png';
import program3 from '../img/scholars-program/program_3.png';

import program5 from '../img/scholars-program/program_5.png';
import program6 from '../img/scholars-program/program_6.png';
import program7 from '../img/scholars-program/program_7.png';

import application from '../img/scholars-program/application.png'
import applicationMobile from '../img/scholars-program/application_mobile.png';
import scholarObj1 from '../img/scholars-program/scholarshipObj1.png';
import scholarObj2 from '../img/scholars-program/scholarshipObj2.png';
import scholarObj3 from '../img/scholars-program/scholarshipObj3.png';

import programStructure1 from '../img/scholars-program/program-structure/mentorship.png';
import programStructure2 from '../img/scholars-program/program-structure/creativeThinking.png';
import programStructure3 from '../img/scholars-program/program-structure/test.png';
import programStructure4 from '../img/scholars-program/program-structure/openEnrollment.png';
import programStructure5 from '../img/scholars-program/program-structure/tuition.png';
import programStructure6 from '../img/scholars-program/program-structure/students.png';
import programStructure7 from '../img/scholars-program/program-structure/network.png';


const Scholarships = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const renderProgramDesktopView = () => {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBlock: '4%', gap: 60, background: "linear-gradient(to left, #007B43, #000404)" }}>
                <span className="section-title-start title-font" style={{ marginLeft: '20px' }}>Program Plan</span>
                <img src={ProgramPlan} style={{ backgroundSize: 'cover' }} />
            </div>
        )
    }

    const renderProgramMobileView = () => {

        const program = [
            {
                id: 1,
                title: 'University Selection & Shortlisting',
                description: 'Helping students choose programs.',
                icon: program1
            },
            {
                id: 2,
                title: 'Advanced Test Prep',
                description: 'SAT, ACT, TOEFL, IELTS coaching.',
                icon: program2
            },
            {
                id: 3,
                title: 'Application Support',
                description: 'Personal statements, essays,\nrecommendations.',
                icon: program3
            },
            {
                id: 4,
                title: 'Financial aid',
                description: 'Tuition fee, travel, living expenses.',
                icon: program5
            },
            {
                id: 5,
                title: 'Mock Interviews',
                description: 'Training for university admissions.',
                icon: program6
            },
            {
                id: 6,
                title: 'Financial Aid &\nScholarships',
                description: 'Guidance on FAFSA, CSS Profile,\nand university-based scholarship',
                icon: program7
            }
        ];

        const renderItem = (item) => {

            return (
                <div key={item.id} style={{ display: 'flex', flexDirection: 'row', gap: '25%', alignItems: 'center' }}>
                    <img src={item.icon} style={{ backgroundSize: 'cover', width: 50, height: 50 }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', left: '25%' }}>
                        <span style={{ width: 50, height: 2, backgroundColor: 'white' }} />
                        <span style={{ width: 10, height: 10, borderRadius: 20, backgroundColor: 'white' }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                        <span style={{ fontSize: '16px', color: 'white', fontFamily: 'League Spartan', lineHeight: '18px' }}>{item.title}</span>
                        <span style={{ fontSize: '12px', color: 'white', whiteSpace: 'pre-wrap', color: '#CCCCCC' }}>{item.description}</span>

                    </div>
                </div>
            )
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '10%', paddingBlock: '16%', gap: 20, background: "linear-gradient(to left, #007B43, #000404)" }}>
                <span className="section-title-start mobile-font text-center">Program Plan</span>
                {
                    program.map((item) => (
                        renderItem(item)
                    ))
                }
            </div>
        )
    }

    const renderProgramPlan = () => {

        return isMobile ? renderProgramMobileView() : renderProgramDesktopView()
    }

    const renderApplicationMobileView = () => {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBlock: '4%', gap: 60 }}>
                <span className="section-title-start mobile-font text-center" style={{ color: '#0D3C42' }}>Application & Selection Process</span>
                <img src={applicationMobile} style={{ backgroundSize: 'cover' }} />
            </div>
        )
    }

    const renderApplicationDesktopView = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBlock: '4%', gap: 40 }}>
                <span className="section-title-start title-font" style={{ color: '#0D3C42' }}>Application & Selection Process</span>
                <img src={application} style={{ backgroundSize: 'cover' }} />
            </div>
        )
    }

    const renderApplicationView = () => {

        return isMobile ? renderApplicationMobileView() : renderApplicationDesktopView()
    }

    const renderContactUsView = () => {

        return isMobile ? renderContactUsMobileView() : renderContactUsDesktopView()
    }

    const renderContactUsDesktopView = () => {

        const onClick = () => {
            window.open('https://docs.google.com/forms/d/e/1FAIpQLSfi1BmW9ikzhw4gR0Alu1K9em887XJUk-MMIp2Gwo9oSkXtZA/viewform')
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBottom: '4%', paddingTop: '4%', gap: 250 }} className="contactus-background">
                <span className="section-title-start title-font" style={{ whiteSpace: 'pre-wrap' }}>{'Contact & Application\nPortal'}</span>
                <div style={{ alignItems: 'center', gap: 10, display: 'flex', flexDirection: 'column', fontSize: '24px', fontWeight: '500', marginTop: '100px', textAlign: 'center' }}>
                    <div style={{ borderRadius: 20, backgroundColor: 'white', padding: 10, paddingInline: 20, fontWeight: '500', marginBottom: '20px', fontSize: '34px', color: '#0D3C42', cursor: 'pointer' }} onClick={onClick}>APPLY NOW</div>
                    <span style={{ color: 'white' }}>Queries/Support Contact Information:  Purshottam Sharma</span>
                    <a href="mailto:Purshottam@athenaeducation.co.in"><span style={{ color: 'white', textDecoration: 'underline' }}>Purshottam@athenaeducation.co.in</span></a>
                    <span style={{ color: 'white' }}>7015247327</span>
                </div>
            </div>
        )
    }

    const renderContactUsMobileView = () => {
        const onClick = () => {
            window.open('https://docs.google.com/forms/d/e/1FAIpQLSfi1BmW9ikzhw4gR0Alu1K9em887XJUk-MMIp2Gwo9oSkXtZA/viewform')
        }
        return (
            <div className="contactus-mobile" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '200px', paddingBlock: 20, paddingTop: 40 }}>

                <span className="section-title-start mobile-font" style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>{'Contact &\nApplication Portal'}</span>
                <div style={{ alignItems: 'center', gap: 10, display: 'flex', flexDirection: 'column', fontSize: '15px', fontWeight: '500', marginTop: '100px', textAlign: 'center' }}>

                    <div style={{ borderRadius: 10, backgroundColor: 'white', padding: 5, paddingInline: 25, fontWeight: '500', marginBottom: '20px', fontSize: '20px', fontWeight: '500' }} onClick={()=>{onClick()}}>APPLY NOW</div>

                    <span style={{ color: 'white' }}>Queries/Support Contact Information:  Purshottam Sharma</span>
                    <a href="mailto:Purshottam@athenaeducation.co.in"><span style={{ color: 'white', textDecoration: 'underline' }}>Purshottam@athenaeducation.co.in</span></a>
                    <span style={{ color: 'white' }}>7015247327</span>
                </div>
            </div>
        )
    }

    const renderIntroDesktopView = () => {

        return (
            <div className="intro-view" style={{ paddingTop: '8%', paddingBottom: '16%', paddingInline: '5%', gap: 100, display: 'flex', flexDirection: 'column' }}>
                <span className="section-title-start title-font">Study Abroad Scholarship 2025</span>
                <div className="section-box transparent-bg">
                    <p className="section-body" style={{ fontSize: '36px' }}>
                        The Study Abroad Scholarship Program is designed to provide mentoring and guidance to academically talented 12th-grade underprivileged students who aspire to study at top universities abroad as well as India. This initiative focuses on skill development, standardized test preparation, university applications, and exposure to global/local education opportunities. The program does not offer direct financial aid but ensures students receive the necessary mentorship and resources to maximize their chances of admission to prestigious institutions.
                    </p>
                </div>
            </div>
        )
    }

    const renderMobileIntroView = () => {
        return (
            <div className="intro-view" style={{ paddingTop: '16%', paddingBottom: '16%', paddingInline: '5%', gap: 40, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <span className="section-title-start mobile-font">Study Abroad Scholarship 2025</span>
                <div className="section-box transparent-bg">
                    <p className="section-body">
                        The Study Abroad Scholarship Program is designed to provide mentoring and guidance to academically talented 12th-grade underprivileged students who aspire to study at top universities abroad as well as India. This initiative focuses on skill development, standardized test preparation, university applications, and exposure to global/local education opportunities. The program does not offer direct financial aid but ensures students receive the necessary mentorship and resources to maximize their chances of admission to prestigious institutions.
                    </p>
                </div>
            </div>
        )
    }

    const renderIntroView = () => {

        return isMobile ? renderMobileIntroView() : renderIntroDesktopView();
    }

    const renderscholarDesktopView = (scholarViewItems) => {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBlock: '4%', gap: 80 }}>
                <span className="section-title-start title-font" style={{ color: '#0D3C42' }}>Scholarship Objectives</span>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10%' }}>
                    <img src={ScholarshipGrad} alt="Graduate Student" className="objectives-img" />
                    <ul className="objectives-list" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBlock: 10 }}>
                        {
                            scholarViewItems.map((item, index) => (
                                <>
                                    <li style={{ zIndex: 999, fontSize: '26px', fontFamily: 'LeagueSpartan-light', whiteSpace: 'pre-wrap' }} key={item.id}><span className="bullet" ><div style={{ padding: 2, backgroundColor: '#0D3C42', boarderRadius: 2, width: '30px', height: '30px', justifyContent: 'center', alignItems: 'center', borderRadius: 20 }}><img src={item.icon} /></div></span> {item.name} </li>
                                </>

                            ))

                        }
                        <span style={{ height: 320, width: 2, backgroundColor: '#0D3C42', marginLeft: 15, position: 'absolute', zIndex: 10, marginTop: 5 }} />

                    </ul>
                </div>
            </div>
        )
    }

    const renderScholarMobileView = (scholarViewItems) => {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBlock: '4%', gap: 40, alignItems: 'center' }}>
                <span className="section-title-start mobile-font" style={{ color: '#0D3C42', textAlign: 'center' }}>Scholarship Objectives</span>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10%' }}>
                    <img src={ScholarshipGrad} alt="Graduate Student" className="objectives-img" />
                    <ul className="objectives-list" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBlock: 10, marginTop: 40 }}>
                        {
                            scholarViewItems.map((item, index) => (
                                <>
                                    <li style={{ zIndex: 999, fontSize: '16px', fontFamily: 'LeagueSpartan-light' }} key={item.id}><span className="bullet" ><div style={{ padding: 2, backgroundColor: '#0D3C42', boarderRadius: 2, width: '30px', height: '30px', justifyContent: 'center', alignItems: 'center', borderRadius: 20 }}><img src={item.icon} /></div></span> {item.name} </li>
                                </>

                            ))

                        }
                        <span style={{ height: 150, width: 2, backgroundColor: '#0D3C42', marginLeft: 15, position: 'absolute', zIndex: 10, marginTop: 10 }} />

                    </ul>
                </div>
            </div>
        )
    }

    const renderScholarView = () => {

        const scholarViewItems = [
            {
                id: 1,
                name: 'Identify and support high-potential students.',
                icon: scholarObj1
            },
            {
                id: 2,
                name: 'Provide structured mentorship, skill-building workshops, test preparation, and financial assistance.',
                icon: scholarObj2
            },
            {
                id: 3,
                name: 'Guide students through university and financial aid\napplications.',
                icon: scholarObj3
            }
        ]

        return (
            isMobile ? renderScholarMobileView(scholarViewItems) : renderscholarDesktopView(scholarViewItems)
        )
    }

    const renderMissionVissionMobileView = (missioncopies) => {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBlock: '8%', gap: 40, alignItems: 'center' }} className="mindset-vision-view">
                <span className="section-title-start mobile-font" style={{ textAlign: 'center' }}>Mission & Vision</span>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                    {
                        missioncopies.map(item => (
                            <div className="section-box transparent-bg" key={item.id}>
                                <p className="section-body-without-center" style={{ fontSize: '16px' }}>
                                    <span style={{ fontFamily: 'LeagueSpartan-Bold' }}>{item.name}:</span> {item.description}
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    const renderMissionVissionDesktopView = (missioncopies) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBottom: '4%', paddingTop: '4%', gap: 80 }} className="mindset-vision-view">
                <span className="section-title-start title-font">Mission & Vision</span>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                    {
                        missioncopies.map(item => (
                            <div className="section-box transparent-bg" key={item.id}>
                                <p className="section-body-without-center" style={{ fontSize: '36px' }}>
                                    <span style={{ fontFamily: 'LeagueSpartan-Bold' }}>{item.name}:</span> {item.description}
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    const renderMissionVisionView = () => {
        const missioncopies = [
            {
                id: 1,
                name: 'Mission',
                description: 'Mentor underprivileged students, equipping them with guidance, skills, and financial support to achieve academic success.'
            },
            {
                id: 2,
                name: 'Vision',
                description: 'Every talented student in India, regardless of background, gets mentorship and financial aid to secure admission to top universities.'
            }
        ]
        return (
            isMobile ? renderMissionVissionMobileView(missioncopies) : renderMissionVissionDesktopView(missioncopies)
        )
    }

    const renderProgramStructureDesktopView = (programStructure) => {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBlock: '4%', gap: 40 }}>
                <span className="section-title-start title-font" style={{ color: '#0D3C42' }}>Program Structure</span>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

                    <span style={{ fontSize: '24px' }}><span style={{ fontFamily: 'LeagueSpartan-SemiBold' }}>Duration:</span> <span style={{ fontFamily: 'LeagueSpartan-Light' }}>6 months to 1 year (based on student program needs)</span></span>
                    <span style={{ fontSize: '24px', fontFamily: 'LeagueSpartan-SemiBold' }}>Components:</span>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                    {
                        programStructure.map(item => (
                            <div key={item.id} style={{ padding: 10, width: 250, height: 200, background: 'linear-gradient(180deg, rgba(121,176,63,1) 0%, rgba(0,123,67,1) 100%)', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, borderRadius: 20 }}>
                                <img src={item.icon} />
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
                                    <span style={{ fontSize: '18px', fontFamily: 'LeagueSpartan-SemiBold', color: 'white' }}>{item.name}</span>
                                    <span style={{ fontSize: '14px', fontFamily: 'LeagueSpartan-Light', color: 'white', textAlign: 'center', paddingInline: '20px' }}>{item.description}</span>
                                </div>

                            </div>
                        ))
                    }
                </div>

            </div>
        )
    }

    const renderProgramStructureMobileView = (programStructure) => {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '1%', paddingBlock: '4%', gap: 40 }}>
                <div style={{paddingInline:'10px',alignItems:'center',display:'flex',flexDirection:'column'}}>
                    <span className="section-title-start mobile-font" style={{ color: '#0D3C42',textAlign:'center' }}>Program Structure</span>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

                        <span style={{ fontSize: '16px' }}><span style={{ fontFamily: 'LeagueSpartan-SemiBold' }}>Duration:</span> <span style={{ fontFamily: 'LeagueSpartan-Light' }}>6 months to 1 year (based on student program needs)</span></span>
                        <span style={{ fontSize: '16px', fontFamily: 'LeagueSpartan-SemiBold',textAlign:'center' }}>Components:</span>
                    </div>
                </div>
               
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20, alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                    {
                        programStructure.map(item => (
                            <div key={item.id} style={{ padding: 5,paddingTop:10, width: 160, height: 180, background: 'linear-gradient(180deg, rgba(121,176,63,1) 0%, rgba(0,123,67,1) 100%)', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, borderRadius: 20 }}>
                                <img src={item.icon} style={{width:30,height:30,backgroundSize:'cover'}}/>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
                                    <span style={{ fontSize: '16px', fontFamily: 'LeagueSpartan-SemiBold', color: 'white',textAlign:'center' }}>{item.name}</span>
                                    <span style={{ fontSize: '12px', fontFamily: 'LeagueSpartan-Light', color: 'white', textAlign: 'center', paddingInline: '10px' }}>{item.description}</span>
                                </div>

                            </div>
                        ))
                    }
                </div>

            </div>
        )
    }

    const renderProgramStructure = () => {

        const programStructure = [
            {
                id: 1,
                name: 'Mentorship',
                description: 'One-on-one guidance from experts and alumni.',
                icon: programStructure1
            },
            {
                id: 2,
                name: 'Skill Training',
                description: 'Research, writing, and critical thinking development.',
                icon: programStructure2
            },
            {
                id: 3,
                name: 'Test Prep',
                description: 'SAT, ACT, TOEFL, IELTS support.',
                icon: programStructure3
            },
            {
                id: 4,
                name: 'Application Support',
                description: 'University selection, essay writing, project and interview prep.',
                icon: programStructure4
            },
            {
                id: 5,
                name: 'Financial Support',
                description: 'Assistance with application and test fees, plus guidance on securing financial aid and scholarships.',
                icon: programStructure5
            },
            {
                id: 6,
                name: 'Exposure',
                description: 'Interactions with university reps, virtual college fairs, networking.',
                icon: programStructure6
            },
            {
                id: 7,
                name: 'Community Engagement',
                description: 'Peer support and learning',
                icon: programStructure7
            }
        ]


        return (
            isMobile ? renderProgramStructureMobileView(programStructure) : renderProgramStructureDesktopView(programStructure)
        )
    }

    const renderEligiblityDesktopView = (eligblityItems) => {
        const renderEligiblityItem = (item) => {
            return (
                <div className="section-box transparent-bg-without-padding" style={{width:350,marginBlock:10,padding:'10px',paddingBlock:'25px'}} key={item.id}>
                    {/* <div style={{background: "linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(116,116,116,1) 100%)",height:1,width:80,position:'absolute',right:0,alignContent:'center',justifyContent:'center',justifyItems:'center',display:'flex',top:'55%'}}/> */}
                    <div className="section-body" style={{ display:'flex',flexDirection:'column',gap:'20px' }}>
                        <span style={{fontFamily:'LeagueSpartan-SemiBold',fontSize:'24px'}}>{item.title}</span>
                        <span style={{fontFamily:'LeagueSpartan-Light',fontSize:'18px',paddingInline:'15px',whiteSpace:'pre-wrap'}}>{item.description}</span>
                    </div>
                    
                </div>
            )
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBottom: '4%', paddingTop: '4%', gap: 80 }} className="mindset-vision-view">
                <span className="section-title-start title-font">Eligibility Criteria</span>
                <div style={{display:'flex',flexWrap:'wrap',gap:20}}>
                    {
                        eligblityItems.map(item => (
                            renderEligiblityItem(item)
                        ))
                    }
                   
                
                </div>
               
            </div>
        )
    }

    const renderEligiblityMobileView = (eligblityItems) => {
        const renderEligiblityItem = (item) => {
            return (
                <div className="section-box transparent-bg-without-padding" style={{width:280,marginBlock:5,padding:'10px',paddingBlock:'25px'}} key={item.id}>
                    {/* <div style={{background: "linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(116,116,116,1) 100%)",height:1,width:80,position:'absolute',right:0,alignContent:'center',justifyContent:'center',justifyItems:'center',display:'flex',top:'55%'}}/> */}
                    <div className="section-body" style={{ display:'flex',flexDirection:'column',gap:'20px' }}>
                        <span style={{fontFamily:'LeagueSpartan-SemiBold',fontSize:'18px'}}>{item.title}</span>
                        <span style={{fontFamily:'LeagueSpartan-Light',fontSize:'14px',paddingInline:'15px',whiteSpace:'pre-wrap'}}>{item.description}</span>
                    </div>
                    
                </div>
            )
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingInline: '8%', paddingBottom: '4%', paddingTop: '8%', gap: 20 }} className="mindset-vision-view">
                <span className="section-title-start mobile-font" style={{textAlign:'center'}}>Eligibility Criteria</span>
                <div style={{display:'flex',flexWrap:'wrap',gap:20}}>
                    {
                        eligblityItems.map(item => (
                            renderEligiblityItem(item)
                        ))
                    }
                   
                
                </div>
               
            </div>
        )
    }

    const renderEligblityView = () => {

        const eligblityItems = [
            {
                id:1,
                title:'Grade Level',
                description:'12th-grade students (Pass-out\nYear: 2026).'
            },
            {
                id:2,
                title:'Science students',
                description:'Minimum 92%+ consistently from 9th to 11th grade.'
            },
            {
                id:3,
                title:'Humanities & Commerce',
                description:'Minimum 88%+ consistently from 9th to 11th grade.'
            },
            {
                id:4,
                title:'English Proficiency',
                description:'80%+ in English throughout 9th to 11th grade.'
            },
            {
                id:5,
                title:'Financial Requirement',
                description:'Family income below ₹8,00,000\nper annum.'
            },
            {
                id:6,
                title:'Financial Support Eligibility',
                description:'Priority will be given to students demonstrating high financial need.'
            }
        ]

        return isMobile ? renderEligiblityMobileView(eligblityItems) : renderEligiblityDesktopView(eligblityItems)
    }


    return (
        <div >
            {renderIntroView()}
            {renderScholarView()}
            {renderMissionVisionView()}
            {renderProgramStructure()}
            {renderEligblityView()}
            {/* Introduction Section */}





            {/* <section className="section program-structure gradient-overlay">
                <h2 className="section-title green">Program Structure</h2>
                <p className="section-subtitle green"><strong>Duration: </strong>6 months to 1 year (based on student program needs)</p>
                <p className="section-subtitle green"><strong>Components: </strong></p>
                <div className="program-grid">
                    <div className="program-box gradient-overlay">Mentorship<br /><small>1-on-1 mentorship, goal setting and review</small></div>
                    <div className="program-box gradient-overlay">Skill Training<br /><small>Training sessions on reading, writing & communication</small></div>
                    <div className="program-box gradient-overlay">Test Prep<br /><small>SAT, TOEFL, IELTS support</small></div>
                    <div className="program-box gradient-overlay">Application Support<br /><small>University & scholarship application help</small></div>
                    <div className="program-box gradient-overlay">Financial Support<br /><small>Application fees, test prep support, etc.</small></div>
                    <div className="program-box gradient-overlay">Exposure<br /><small>Webinars, speaker series, and interaction</small></div>
                    <div className="program-box gradient-overlay">Community Engagement<br /><small>Group learning & volunteering</small></div>
                </div>
            </section> */}

            {/* <section className="section eligibility gradient-overlay">
                <h2 className="section-title">Eligibility Criteria</h2>
                <div className="eligibility-grid">
                    <div className="eligibility-box">Grade Level<br /><small>12th grade students (Pass-out: Year 2024)</small></div>
                    <div className="eligibility-box">Science Students<br /><small>92% consistently from 9th to 11th grades</small></div>
                    <div className="eligibility-box">Humanities & Commerce<br /><small>88% consistently from 9th to 11th grades</small></div>
                    <div className="eligibility-box">English Proficiency<br /><small>80%+ in English throughout 9th to 11th grades</small></div>
                    <div className="eligibility-box">Financial Requirement<br /><small>Family income below ₹8,00,000/year</small></div>
                    <div className="eligibility-box">Financial Support Eligibility<br /><small>Priority to students with high need</small></div>
                </div>
            </section> */}

            {renderApplicationView()}
            {renderProgramPlan()}
            {renderContactUsView()}



        </div>
    );
};

export default Scholarships;
